<!--
 * @Description: 首页容器
 * @Autor: WangYuan
 * @Date: 2021-06-10 16:20:52
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-01-27 19:42:52
-->
<template>
  <div>
    <div class="main">
      <!-- 侧边菜单 -->
      <ul v-if="isMeau" class="main-meau">
        <img class="w40 auto-center mt10 mb30 radius" :src="project.logo" />
        <template v-for="(item, index) in meauList">
          <router-link :to="{ path: item.path }">
            <li class="main-meau-item">
              <i class="icon" :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </li>
          </router-link>
        </template>
      </ul>

      <!-- 内容 -->
      <div class="main-body">
        <top-bar></top-bar>
        <div class="main-body-content">
          <router-view></router-view>
        </div>
        <!--底部菜单-->
        <div class="bottom"
          v-if="routename == 'bottomNavigation' || routename == 'topNavigation' || routename == 'backgroundColor'">
          <el-button @click="cancleInfo">取消</el-button>
          <el-button type="primary" @click="saveInfo">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import { mapMutations, mapGetters } from "vuex";
import { settingProject } from "@/utils/auth";

import { checkToken, EditInfo } from '@/api/user';

export default {
  name: "mall",

  components: {
    TopBar,
  },

  created() {
    // 默认设置首页为当前正在构建页面
    this.setHomePage();
    //this.refreshToken();
    // 利用闭包保存project数据,在刷新时保存
    let temp = this.project;

    // 当页面刷新时，把当前项目数据保存localStorage中
    window.addEventListener("beforeunload", function (e) {
      settingProject(temp);
    });
    if (this.$route.path == '/mall/control/navigation-tpl') {
      this.routename = 'bottomNavigation'
    } else if (this.$route.path == '/mall/control/list-tpl') {
      this.routename = 'topNavigation'
    } else {
      this.routename = ''
    }
  },

  data() {
    return {
      routename: '',
      isMeau: true,
      meauList: [
        {
          name: "商城",
          icon: "icon-shop",
          path: "/mall/store",
        },
        {
          name: "页面",
          icon: "icon-fuzhiyemian",
          path: "/mall/pages-manage",
        },
        /*{
          name: "商品",
          icon: "icon-shangpin-meau",
          path: "/mall/goods",
        },*/
        {
          name: "构建",
          icon: "icon-list",
          path: "/mall/page-build",
        },
        /*  {
            name: "模板",
            icon: "icon-list",
            path: "/mall/model-manage",
          },*/
        {
          name: "控件",
          icon: "icon-list",
          path: "/mall/control",
        },
        {
          name: "槽位",
          icon: "icon-list",
          path: "/mall/slotmanage",
        },
      ],
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: "",
      globalTop: {}
    };
  },

  watch: {
    "$route.path": {
      handler() {
        if (this.$route.path == '/mall/page-build') {
          this.isMeau = false;
        } else {
          this.isMeau = true;
        }

        if (this.$route.path == '/mall/control/navigation-tpl') {
          this.routename = 'bottomNavigation'
        } else if (this.$route.path == '/mall/control/list-tpl') {
          this.routename = 'topNavigation'
        } else {
          this.routename = ''
        }
        // console.log("this.$route.path", this.$route.path);
      },
      deep: true,
      immediate: true,
    }
  },

  computed: {
    ...mapGetters(["project"]),
  },

  methods: {
    ...mapMutations(["setHomePage"]),
    // 实时检测刷新token
    refreshToken() {


      this.refreshTime = setInterval(() => {
        checkToken(this.refreshTime, this.refreshLock);
      }, 60000);


    },
    saveInfo() {
      let params, params1;
      if (this.routename == 'topNavigation') {
        let topNavigation = {
          label: "顶部导航",
          styles: this.$store.state.globalTop
        }
        params = JSON.stringify(topNavigation)
        params1 = { topNavigation: params, configId: this.project.configEntity.configId, }
        this.project.configEntity.topNavigation = topNavigation
        this.$store.commit('setProject', this.project)
      } else if (this.routename == 'bottomNavigation') {
        params = JSON.stringify(this.$store.state.globalBottom)
        this.project.configEntity.bottomNavigation = params
        this.$store.commit('setProject', this.project)
        params1 = { bottomNavigation: params, configId: this.project.configEntity.configId }
      }
      EditInfo(params1).then(res => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
        } else {
          this.$message({
            type: "warning",
            message: "保存失败!",
          });
        }
      })
    },
    //取消
    cancleInfo() {
      let styles = JSON.parse(this.project.configEntity.topNavigation).styles
      this.$store.commit('setUpdateGlobalTop', true)
      this.$store.commit('setGlobalTop', styles)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variable";

.main {
  display: flex;
  height: 100vh;
  background: #f7f8fa;

  .main-body {
    flex: 1;

    .main-body-content {
      position: relative;
      height: calc(100% - 60px);
      margin-top: 4px;
      //width: calc(100vw - 120px);
      overflow: hidden;
    }
  }
}

.main-meau {
  width: 120px;
  height: 100%;
  padding-top: 16px;
  border-right: 1px solid #ebedf0;
  background: #fff;

  .main-meau-item {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    padding: 11px 20px;
    color: #7d7e80;
    cursor: pointer;

    i {
      margin-right: 5px;
      font-size: 18px;
      color: #7d7e80;
    }
  }

  .active {
    i {
      color: $color-theme !important;
    }

    .main-meau-item {
      position: relative;
      color: $color-theme !important;

      &::after {
        position: absolute;
        top: 10px;
        right: 10px;
        content: "";
        width: 4px;
        height: 21px;
        border-radius: 3px;
        background: $color-theme;
      }
    }
  }
}

.bottom {
  position: fixed;
  height: 80px;
  line-height: 60px;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  z-index: 10;

  el-button {
    height: 40px;
  }
}
</style>
