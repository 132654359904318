<!--
 * @Description: PC头部菜单
 * @Autor: WangYuan
 * @Date: 2021-05-19 14:07:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-12-15 17:09:06
-->
<template>
  <div>
    <div class="topBar">
      <!-- 左侧内容 -->
      <div>
        <span v-if="isBack" @click="toPagesManage">
          <i class="el-icon-arrow-left"></i>
          <span class="f14 pointer">返回</span>
        </span>
        <span class="f14 pointer ml20" @click="back">切换应用</span>
      </div>

      <!-- 右侧内容 -->
      <div>
        <!-- <git-control />
        <el-button size="small" @click="toSchema">schema 生成器</el-button>

        <el-button size="small ml10" @click="viewQr">商城二维码</el-button> -->

        <el-button size="small ml10" icon="el-icon-view" @click="show = true">实时预览</el-button>

        <el-button size="small f-white bg-theme" icon="el-icon-tickets" @click="openSave">保存</el-button>

        <el-button size="small ml10" @click="onLogout">退出</el-button>
      </div>
    </div>

    <real-timeView :show.sync="show"></real-timeView>

    <save-dialog ref="save"></save-dialog>

    <qr-dialog ref="qr"></qr-dialog>
  </div>
</template>

<script>
import RealTimeView from "./RealTimeView.vue";
import SaveDialog from "@/components/SaveDialog";
import QrDialog from "@/components/QrDialog";
import jrQrcode from "jr-qrcode";
import { mapGetters, mapMutations } from "vuex";
import { editProject, pageSave, shopUpdate } from "@/api/project";
import {getProjectDetail} from "@/api/easyApp";
import {deepClone} from "../../js/utils";


export default {
  components: {
    RealTimeView,
    SaveDialog,
    QrDialog,
  },

  provide() {
    return {
      topBar: this,
      issave:false
    };
  },
  data() {
    return {
      show: false,
      isBack: false
    };
  },

  computed: {
    ...mapGetters(["project", "userInfo", "curPage"]),
  },

  watch: {
    "$route.path": {
      handler() {
        if (this.$route.path == '/mall/page-build') {
          this.isBack = true;
        } else {
          this.isBack = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },

  mounted() {
    //console.log("this.issavethis.issavethis.issave:",this.issave);
  },

  methods: {
    ...mapMutations(["logout", "setProject"]),
    // 返回商城管理
    back() {
      this.$router.push({ name: "managet" });
    },

    openSave(view = false) {
      // this.$refs.save.open(view);

      this.project.pagesEntityList.forEach(ele => {
        if (ele.pagesId == this.curPage.pagesId){
          ele.componentEntityList = this.curPage.componentEntityList;
        }
      });


      if (this.curPage.pagesId) {

        let params=deepClone(this.curPage);
         params.backgroundColor=JSON.stringify(params.backgroundColor);


        //保存的时候保存下单位
      /*  let data={
          ...this.curPage
        }*/
        let data={
          ...params
        }

        //存储点位跳转
        let index=this.curPage.componentEntityList.findIndex((item) => item.component == 'McTagNav');


        //console.log("this.curPage.componentEntityList[index].otherSet.supportLink:",this.curPage.componentEntityList[index].otherSet.supportLink);
         console.log("index:",index);

        if(index==-1||(index!=-1&&this.curPage.componentEntityList[index].otherSet.supportLink==false)){
          console.log("0000");
          //delete data['linkPoints'];
          data['linkPoints']=null;
        }
        else if(index!=-1&&this.curPage.componentEntityList[index].otherSet.supportLink==true){
          console.log("11111");
          data['linkPoints']=this.btnGetLinkPoints();
        }


        //存储数据源传参 McArticle(value)  McArtDetail(value)   McStudys(attrs)
        //McStudys   McCourseDetail
        let initIndex=this.curPage.componentEntityList.findIndex((item) => item.component == 'McArticle'||item.component == 'McArtDetail'||item.component == 'McStudys'||item.component == 'McCourseDetail');

        if(initIndex==-1){
         // delete data['dsInitParams'];
          data['dsInitParams']=null;
        }
        else {
         // console.log("dsInitParamsdsInitParamsdsInitParamsdsInitParams");
          let dsInitParams=this.btnGetInitParams();
          console.log("dsInitParamsxxxxxx:",dsInitParams);
          if(dsInitParams.length>0){
            data['dsInitParams']=JSON.stringify(dsInitParams);;
          }
          else{
            //delete data['dsInitParams'];
            data['dsInitParams']=null;
          }

        }

        console.log("data:",data);



        shopUpdate(data).then(res =>{
          if (res.code == 0) {
            this.$message({
              message: '保存成功',
              type: 'warning'
            });
            this.issave = true;
           // console.log("==this.project==:",this.project);
            this.setProject(this.project);
          }
        }).catch((err) => {
          this.$message.error(err.msg);
        });

      } else {

       // console.log("2222");

        if(!this.curPage.pagesId){
          this.$message({
            message: '没更改任何页面',
            type: 'success'
          });
          return;
        }

        pageSave(this.curPage).then(res =>{
          if (res.code == 0) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.issave = true;
            this.getProject();
            this.setProject(this.project);

          }
        }).catch((err) => {
          this.$message.error(err.msg);
        });


      }


    },
    //获取点位参数
    btnGetLinkPoints(){
      let index=this.curPage.componentEntityList.findIndex((item) => item.component == 'McTagNav');

      //保存点位跳转
      let linkPoints=[];

        if (this.curPage.componentEntityList[index].otherSet.tagGroupSet == 'auto') {

          let clickId=this.curPage.componentEntityList[index].otherSet.clickId;
          let showName=this.curPage.componentEntityList[index].otherSet.showName;
          let title=this.curPage.componentEntityList[index].otherSet.signChinaName;
          console.log("this.curPage.componentEntityList[index].otherSet:",this.curPage.componentEntityList[index].otherSet);

          linkPoints= [
            {
              "id": this.curPage.componentEntityList[index].id,
              "type": "remote",
              "sign":clickId,
              "name":showName,
              "title":title,
              "url": this.curPage.componentEntityList[index].otherSet.tagSource,
              "groupModel":this.curPage.componentEntityList[index].otherSet.groupModel,
              components:this.curPage.componentEntityList[index].otherSet.components,
            }
          ]

        }
        else if (this.curPage.componentEntityList[index].otherSet.tagGroupSet == 'hand') {

          let clickId=this.curPage.componentEntityList[index].otherSet.clickId;
          let showName=this.curPage.componentEntityList[index].otherSet.showName;
          let title=this.curPage.componentEntityList[index].otherSet.signChinaName;

          linkPoints= [
            {
              "id": this.curPage.componentEntityList[index].id,
              "type": "custom",
              "sign":clickId,
              "name":showName,
              "title":title,
              "data": []
            }
          ]

          console.log("clickId:",clickId);

          for (const i in this.curPage.componentEntityList[index].otherSet.categoryList) {
            let obj = {
              sign:clickId,
              value: this.curPage.componentEntityList[index].otherSet.categoryList[i][clickId],
              name:this.curPage.componentEntityList[index].otherSet.categoryList[i][showName],
              components:this.curPage.componentEntityList[index].otherSet.components,
            }
            linkPoints[0].data.push(obj);
          }



        }
        else if(this.curPage.componentEntityList[index].otherSet.tagGroupSet=='selfSet'&&this.curPage.componentEntityList[index].otherSet.tagShowType=='single'){

          let clickId=this.curPage.componentEntityList[index].otherSet.sign;
          let title=this.curPage.componentEntityList[index].otherSet.signSelfSetChinaName;


          linkPoints=[
            {
              "id": this.curPage.componentEntityList[index].id,
              "type": "custom",
              "sign":clickId,
              "name":'name',
              "title":title,
              "data": []
            }
          ]

          for(const i in this.curPage.componentEntityList[index].otherSet.selfCategory){
            let obj={
              sign:this.curPage.componentEntityList[index].otherSet.sign,
              value:this.curPage.componentEntityList[index].otherSet.selfCategory[i].value,
              name:this.curPage.componentEntityList[index].otherSet.selfCategory[i].name,
              components:this.curPage.componentEntityList[index].otherSet.components,
            }
            linkPoints[0].data.push(obj);
          }

          console.log("单例点位存:",linkPoints);

        }
        else if(this.curPage.componentEntityList[index].otherSet.tagGroupSet=='selfSet'&&this.curPage.componentEntityList[index].otherSet.tagShowType!='single'){

          let clickId='navIndex';
          let title=this.curPage.componentEntityList[index].otherSet.signSelfSetChinaName;

          linkPoints=[
            {
              "id": this.curPage.componentEntityList[index].id,
              "type": "custom",
              "sign":clickId,
              "name":'name',
              "title":title,
              "data": []
            }
          ]

          for(const i in this.curPage.componentEntityList[index].otherSet.selfCategory){
            let obj={
              sign:clickId,
              value:i,
              name:this.curPage.componentEntityList[index].otherSet.selfCategory[i].name,
              components:this.curPage.componentEntityList[index].otherSet.selfCategory[i].components,
            }
            linkPoints[0].data.push(obj);
          }

          console.log("单例点位存:",linkPoints);



        }

        if(linkPoints.length>0){
          linkPoints=JSON.stringify(linkPoints);
          return linkPoints;
        }
        else{
          return null;
        }




    },
    //获取初始化入参参数
    btnGetInitParams(){

     // console.log("btnGetInitParamsbtnGetInitParamsbtnGetInitParams");
      //列表数据源的 McArticle(value)  McArtDetail(value)   McStudys(attrs)
      //relationParams
      let componentEntityList=this.curPage.componentEntityList;
    //  console.log("componentEntityList[i].component:",componentEntityList);
      //McArticle(value)  McArtDetail(value)   McStudys(attrs)

      let dsInitParams=[]

      for(const i in componentEntityList){
      /*  "{组件-id}": {
          "{数据源}": {
            "{前置参数名0}": "{前置选择器数据源标识0}",
                "{前置参数名1}": "{前置选择器数据源标识1}"
          }
        }*/
         //文章详情

        console.log("componentEntityList[i].component=='McStudys'&&componentEntityList[i].attrs.dataSource.relationParams:",componentEntityList[i].component);


        if(componentEntityList[i].component=='McStudys'){
          console.log("componentEntityList[i].attrs:",componentEntityList[i].attrs.dataSource.relationParams);
        }

        if(componentEntityList[i].component=='McArtDetail'&&componentEntityList[i].value.dataSource.relationParams){
          let obj={
            componentId:componentEntityList[i].id,
            tagetSource:componentEntityList[i].value.dataSource.tagetSource,
            relationParams:componentEntityList[i].value.dataSource.relationParams,
         //   dataMapper:componentEntityList[i].value.dataSource.dataMapper,
           // decRule:componentEntityList[i].value.dataSource.decRule
          }

          dsInitParams.push(obj);
        }
        //文章列表
        else if(componentEntityList[i].component=='McArticle'&&componentEntityList[i].value.dataSource.relationParams){

          let obj={
            componentId:componentEntityList[i].id,
            tagetSource:componentEntityList[i].value.dataSource.tagetSource,
            relationParams:componentEntityList[i].value.dataSource.relationParams,
           // dataMapper:componentEntityList[i].value.dataSource.dataMapper,
           // decRule:componentEntityList[i].value.dataSource.decRule
          }

          dsInitParams.push(obj);

        }
        else if(componentEntityList[i].component=='McStudys'&&componentEntityList[i].attrs.dataSource.relationParams){

          console.log("McStudysMcStudysMcStudysMcStudysMcStudys");

          let obj={
            componentId:componentEntityList[i].id,
            tagetSource:componentEntityList[i].attrs.dataSource.tagetSource,
            relationParams:componentEntityList[i].attrs.dataSource.relationParams,
            //dataMapper:componentEntityList[i].value.dataSource.dataMapper,
            //decRule:componentEntityList[i].value.dataSource.decRule
          }
          dsInitParams.push(obj);
        }
        else if(componentEntityList[i].component=='McCourseDetail'&&componentEntityList[i].value.dataSource.relationParams){

          console.log("McStudysMcStudysMcStudysMcStudysMcStudys");

          let obj={
            componentId:componentEntityList[i].id,
            tagetSource:componentEntityList[i].value.dataSource.tagetSource,
            relationParams:componentEntityList[i].value.dataSource.relationParams,
            //dataMapper:componentEntityList[i].value.dataSource.dataMapper,
            //decRule:componentEntityList[i].value.dataSource.decRule
          }
          dsInitParams.push(obj);
        }


      }


      console.log("dsInitParamsxxxxxx:",dsInitParams);

      return dsInitParams;


    },

    toSchema() {
      let { href } = this.$router.resolve({
        path: "/schema",
      });
      window.open(href);
    },

    toPagesManage() {

     // console.log("this.issave:",this.issave);


      if(!this.issave){
          this.$confirm("您的修改还未保存，确定要离开此页面吗?", "", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {

          this.$router.push({ name: "pages-manage" });

            this.issave=false;


        }).catch(()=>{
        })
      }else{


        this.$router.push({ name: "pages-manage" });
        this.issave=false;

      }
    },

    viewQr() {
      this.issave = true
      this.$confirm("如不更新，则预览为上次保存的项目数据?", "是否更新保存", {
        confirmButtonText: "更新保存",
        cancelButtonText: "不更新",
        type: "warning",
      }).then(() => {
        this.openSave(true);
        this.issave = false
      }).catch(()=>{
        this.openQr()
      })
    },

    openQr() {
      this.$refs.qr.open();
    },

    // 保存
    async save() {
      let data = {
        aid: this.project.aid,
        userId: this.userInfo.userId,
        name: this.project.name,
        richText: JSON.stringify(this.project),
      };

      let { code } = await editProject(data);

      if (code == "0")
        this.$notify({
          title: "成功",
          message: "项目保存成功",
          type: "success",
        });
    },

    // 退出
    onLogout() {
      this.$confirm("是否确定退出平台?", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.logout();
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding: 0 20px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
}
</style>
